import { useEffect } from 'react';

import { useAccessToken, useGroup, useHandleSignOut, usePermissions, useRefreshToken } from 'contexts/Authorization/Context';
import { useRouter } from 'next/router';
import { getHomeRoute } from 'utils/functions/getHomeRoute';
import { getRole, RoleType } from 'utils/functions/getRoleType';
import { isTokenExpired } from 'contexts/Authorization/Context.helpers';
import { getItem } from 'utils/functions/localStorage';

export function useProtectedRoute(roleType: RoleType | Array<RoleType>) {
  const { push } = useRouter();
  const token = useAccessToken();
  const permissions = usePermissions();
  const handleSignOut = useHandleSignOut();
  const handleRefreshToken = useRefreshToken();
  const group = useGroup();

  useEffect(() => {
    if (isTokenExpired(getItem('refreshToken'))) {
      console.warn('token expired');
      handleSignOut();
      return;
    }

    if (isTokenExpired(getItem('accessToken'))) {
      handleRefreshToken();
    }

    const currentRoleType = getRole(permissions, group.id);

    if (roleType == null && Boolean(token)) {
      return;
    }

    if (currentRoleType === 'Admin') {
      return;
    }

    if (currentRoleType === 'PendingTeacher') {
      push('/teacher/pending');
      return;
    }

    if (roleType.includes(currentRoleType)) {
      return;
    }

    push(`${getHomeRoute(token, permissions)}?prepath=${window.location.pathname}`);
  }, [roleType]);
}
