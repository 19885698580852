import { useEffect } from 'react';

import { gql } from '@apollo/client';
import { useTempToken } from 'contexts/Authorization/Context';
import { useLazyQuery } from 'utils/hooks/useLazyQuery';

const ResponseSetFragment = gql`
  fragment ResponseSetFragment on QuestionResponseSetType {
    title
    availableResponses {
      id
      asset {
        presignedUrl
        assetId
        s3Key
        contentType
      }
      label
      questionResponseSetId
      value
      ... on QuestionResponseRadioType {
        scoreValue
      }
      ... on QuestionResponseCheckboxType {
        scoreValue
      }
      ... on QuestionResponseDropDownListType {
        scoreValue
      }
    }
  }
`;

const SliderSnippetFragment = gql`
  fragment SliderSnippetFragment on ContentSnippetSliderType {
    slider {
      id
      title
      speed
      arrows
      dots
      autoplay
      animation
      sliderItems {
        id
        quote
        author
        type
      }
    }
  }
`;

const VideoSnippetFragment = gql`
  fragment VideoSnippetFragment on ContentSnippetVideoType {
    id
    sort
    asset {
      alt
      s3Key
      presignedUrl
      contentType
    }
  }
`;

const ImageSnippetFragment = gql`
  fragment ImageSnippetFragment on ContentSnippetImageType {
    id
    sort
    asset {
      alt
      s3Key
      presignedUrl
      contentType
    }
  }
`;

const TextSnippetFragment = gql`
  fragment TextSnippetFragment on ContentSnippetTextType {
    text
  }
`;

const CodeSnippetFragment = gql`
  fragment CodeSnippetFragment on ContentSnippetCodeType {
    asset: location {
      assetId
      s3Key
      presignedUrl
      contentType
    }
  }
`;

const QuestionSnippetFragment = gql`
  fragment QuestionSnippetFragment on ContentSnippetQuestionType {
    question
    mandatory

    configuration {
      id
      __typename
      ... on SingleText {
        description
        maxLength
        minLength
        regex
        messages {
          correct
          incorrect
          example
          incomplete
        }
      }
      ... on MultiText {
        description
        maxLength
        minLength
        regex
        messages {
          correct
          incorrect
          example
          incomplete
        }
      }
      ... on Radio {
        description
        responseSetID
        responseSet {
          id
          ...ResponseSetFragment
        }
        messages {
          correct
          incorrect
          example
          incomplete
        }
      }
      ... on Checkbox {
        description
        maxCheckedResponse
        responseSet {
          id
          ...ResponseSetFragment
        }
        messages {
          correct
          incorrect
          example
          incomplete
        }
      }
      ... on DropDownList {
        description
        responseSet {
          id
          ...ResponseSetFragment
        }
        messages {
          correct
          incorrect
          example
          incomplete
        }
      }
      ... on Number {
        description
        maxValue
        minValue
        defaultValue
        messages {
          correct
          incorrect
          example
          incomplete
        }
      }
      ... on Slider {
        description
        defaultValue
        maxValue
        minValue
        step
        ticks {
          label
          sliderValue
        }
        messages {
          correct
          incorrect
          example
          incomplete
        }
      }
      ... on Match {
        description
        randomise
        matchItems {
          id
          questionConfigurationMatchId
          sort
          text
        }
        responseSet {
          availableResponses {
            asset {
              id: assetId
              s3Key
              presignedUrl
              contentType
              alt
              fileName
              tags
              type
              xAxisFocus
            }
            id
            label
            questionResponseSetId
            value
            ... on QuestionResponseMatchType {
              correctPosition
            }
          }
          id
          # linkedConfigurations [QuestionConfigurationInterface]
          projectId
          title
          type
        }
        type
        __typename
        messages {
          correct
          incorrect
          example
          incomplete
        }
      }
      ... on Calendar {
        description
        maxDate
        minDate
        defaultDate
        messages {
          correct
          incorrect
          example
          incomplete
        }
      }
      ... on MultiResponseText {
        description
        maxResponse
        minResponse
        messages {
          correct
          incorrect
          example
          incomplete
        }
      }
    }
  }
  ${ResponseSetFragment}
`;
const QuestionSnippetFragmentWithAnswers = gql`
  fragment QuestionSnippetFragmentWithAnswers on ContentSnippetQuestionType {
    question
    mandatory

    configuration {
      id
      __typename
      ... on SingleText {
        description
        maxLength
        minLength
        regex
      }
      ... on MultiText {
        description
        maxLength
        minLength
        regex
      }
      ... on Radio {
        description
        responseSetID
        responseSet {
          id
          ...ResponseSetFragment
        }
      }
      ... on Checkbox {
        description
        maxCheckedResponse
        responseSet {
          id
          ...ResponseSetFragment
        }
      }
      ... on DropDownList {
        description
        responseSet {
          id
          ...ResponseSetFragment
        }
      }
      ... on Number {
        description
        maxValue
        minValue
        defaultValue
      }
      ... on Slider {
        description
        defaultValue
        maxValue
        minValue
        step
        ticks {
          label
          sliderValue
        }
      }
      ... on Match {
        description
        randomise
        type
        matchItems {
          id
          questionConfigurationMatchId
          sort
          text
        }
        responseSet {
          availableResponses {
            asset {
              id: assetId
              s3Key
              presignedUrl
              contentType
              alt
              fileName
              tags
              type
              xAxisFocus
            }
            id
            label
            questionResponseSetId
            value
            ... on QuestionResponseMatchType {
              correctPosition
            }
          }
          id
          # linkedConfigurations [QuestionConfigurationInterface]
          projectId
          title
          type
        }
        type
        __typename
      }
      ... on Calendar {
        description
        maxDate
        minDate
        defaultDate
      }
      ... on MultiResponseText {
        description
        maxResponse
        minResponse
      }
    }
    answers(contentPageId: $contentPageId, userFlowStepTrackId: $userFlowStepTrackId) {
      ... on QuestionAnswerMatchType {
        id
        value: answers {
          questionResponseMatchId
          definedPosition
          questionAnswerMatchId
        }
        isMarked
        feedback
      }
      ... on QuestionAnswerSinglelineTextType {
        id
        value: text
        isMarked
        feedback
      }
      ... on QuestionAnswerMultilineTextType {
        id
        value: text
        isMarked
        feedback
      }
      ... on QuestionAnswerRadioType {
        id
        value: questionResponseRadioId
        isMarked
        feedback
      }
      ... on QuestionAnswerCheckboxType {
        id
        value: selectedAnswers {
          questionResponseCheckboxId
        }
        isMarked
        feedback
      }
      ... on QuestionAnswerDropDownListType {
        id
        value: questionResponseDropDownListId
        isMarked
        feedback
      }
      ... on QuestionAnswerNumberType {
        id
        value: numberAnswered
        isMarked
        feedback
      }
      ... on QuestionAnswerSliderType {
        id
        value
        isMarked
        feedback
      }
      ... on QuestionAnswerCalendarType {
        id
        value: dateAnswered
        isMarked
        feedback
      }
      ... on QuestionAnswerMultiResponseTextType {
        id
        value: responses {
          value
        }
        isMarked
        feedback
      }
      ... on QuestionAnswerMatchType {
        id
        value: answers {
          definedPosition
          questionAnswerMatchId
          questionResponseMatchId
        }
        isMarked
        feedback
      }
    }
  }
  ${ResponseSetFragment}
`;

export const ContentSectionsFragment = gql`
  fragment ContentSectionsFragment on ContentSectionType {
    id
    sort
    contentLayoutId
    backgroundColor
    borderColor
    contentEvent {
      id
      event
      eventTitle
    }
    background {
      presignedUrl
    }
    sectionContainers {
      id
      sort
      backgroundColor
      borderColor
      contentEvent {
        id
        event
        eventTitle
      }

      snippets {
        id
        sort
        visible
        __typename
        backgroundColor
        borderColor
        contentEvent {
          id
          event
          eventTitle
        }
        ...SliderSnippetFragment
        ...VideoSnippetFragment
        ...TextSnippetFragment
        ...ImageSnippetFragment
        ...CodeSnippetFragment

        ... on ContentSnippetDocumentType {
          asset {
            s3Key
            presignedUrl
            contentType
          }
        }
        ... on ContentSnippetFormType {
          formType
          form {
            id
            contentPages(status: [PUBLISHED, UNPUBLISHED]) {
              id
              contentSections {
                id
                sort
                sectionContainers {
                  id
                  sort
                  snippets {
                    id
                    sort
                    visible
                    __typename
                    backgroundColor
                    borderColor
                    contentEvent {
                      id
                      event
                      eventTitle
                    }
                    ...VideoSnippetFragment
                    ...TextSnippetFragment
                    ...QuestionSnippetFragment
                    ...ImageSnippetFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${SliderSnippetFragment}
  ${VideoSnippetFragment}
  ${ImageSnippetFragment}
  ${TextSnippetFragment}
  ${QuestionSnippetFragment}
  ${CodeSnippetFragment}
`;

const ContentSectionsFragmentWithAnswers = gql`
  fragment ContentSectionsFragmentWithAnswers on ContentSectionType {
    id
    sort
    sectionContainers {
      id
      sort
      snippets {
        id
        sort
        visible
        __typename
        __typename
        backgroundColor
        borderColor
        contentEvent {
          id
          event
          eventTitle
        }
        ...SliderSnippetFragment
        ...VideoSnippetFragment
        ...ImageSnippetFragment
        ...TextSnippetFragment
        ...CodeSnippetFragment
        ... on ContentSnippetSummaryType {
          contentSnippetQuestion(fetchAnswers: true) {
            id
            ...QuestionSnippetFragmentWithAnswers
          }
          contentSnippetQuestionId
        }
        ... on ContentSnippetFormType {
          formType
          form {
            id
            contentPages(status: [PUBLISHED, UNPUBLISHED]) {
              id
              contentSections {
                id
                sort
                sectionContainers {
                  id
                  sort
                  snippets {
                    id
                    sort
                    visible
                    __typename
                    backgroundColor
                    borderColor
                    contentEvent {
                      id
                      event
                      eventTitle
                    }
                    ...VideoSnippetFragment
                    ...TextSnippetFragment
                    ...QuestionSnippetFragmentWithAnswers
                    ...ImageSnippetFragment
                    ... on ContentSnippetSummaryType {
                      contentSnippetQuestion(fetchAnswers: true) {
                        id
                        ...QuestionSnippetFragmentWithAnswers
                      }
                      contentSnippetQuestionId
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${SliderSnippetFragment}
  ${VideoSnippetFragment}
  ${TextSnippetFragment}
  ${ImageSnippetFragment}
  ${QuestionSnippetFragmentWithAnswers}
  ${CodeSnippetFragment}
`;

export const GetPageQueryById = gql`
  query GetPageQueryById($contentPageId: Int!) {
    contentSection {
      searchContentSections(contentPageId: $contentPageId) {
        ...ContentSectionsFragment
      }
    }
  }
  ${ContentSectionsFragment}
`;

const GetPageQueryByIdWithAnswers = gql`
  query GetPageQueryByIdWithAnswers($contentPageId: Int!, $userFlowStepTrackId: Int!) {
    contentSection {
      searchContentSections(contentPageId: $contentPageId) {
        ...ContentSectionsFragmentWithAnswers
      }
    }
  }
  ${ContentSectionsFragmentWithAnswers}
`;

const GetPageQueryByUrlWithAnswers = gql`
  query GetPageQueryByUrlWithAnswers($contentPageUrl: String!) {
    contentSection {
      getSectionsByPageUrl(contentPageUrl: $contentPageUrl) {
        ...ContentSectionsFragment
      }
    }
  }
  ${ContentSectionsFragment}
`;

const GetPageId = gql`
  query GetPageId($contentPageUrl: String!) {
    contentPage {
      get: getContentPagePerName(
        projectId: "${process.env.REACT_APP_PROJECT_ID}"
        contentPageUrl: $contentPageUrl
      ) {
        id
      }
    }
  }
`;

const pageIds = JSON.parse(process.env.REACT_APP_EKARDO_PAGE_IDS);

function sortFunc(a, b) {
  return a.sort - b.sort;
}

interface Params {
  contentPageId?: number;
  contentPageName?: string;
  contentPageUrl?: string;
  userFlowStepTrackId?: number;
}

export function useEkardoPageData({ contentPageName, contentPageUrl, contentPageId, userFlowStepTrackId }: Params) {
  const hasToken = useTempToken();

  const [getPageDataNoAnswers, { data: pageDataNoAnswers, loading: isGetPageDataNoAnswersLoading }] = useLazyQuery(GetPageQueryById);
  const [getPageDataWithAnswers, { data: pageWithAnswersDataId, loading: isGetPageDataWithAnswersLoading }] =
    useLazyQuery(GetPageQueryByIdWithAnswers);

  const [getPageDataWithAnswersByUrl, { data: pageWithAnswersDataUrl, loading: isGetPageDataByUrlWithAnswersLoading }] =
    useLazyQuery(GetPageQueryByUrlWithAnswers);

  function getPageData(localContentPageId: number) {
    if (userFlowStepTrackId) {
      getPageDataWithAnswers({
        variables: {
          contentPageId: localContentPageId,
          userFlowStepTrackId,
        },
      });
    } else {
      getPageDataNoAnswers({
        variables: {
          contentPageId: localContentPageId,
        },
      });
    }
  }

  const [getPageId, { loading: isGetPageIdLoading }] = useLazyQuery(GetPageId, {
    onCompleted(data) {
      getPageData(data.contentPage.get.id);
    },
  });
  const isPageLoading =
    isGetPageDataNoAnswersLoading || isGetPageDataWithAnswersLoading || isGetPageIdLoading || isGetPageDataByUrlWithAnswersLoading;

  useEffect(() => {
    if (hasToken) {
      if (contentPageName || contentPageId) {
        getPageData(Number.parseInt(pageIds[contentPageName], 10) || contentPageId);
      }
      if (contentPageUrl) {
        getPageId({
          variables: {
            contentPageUrl,
          },
        });
      }
    }
  }, [hasToken, contentPageUrl]);

  const page = [
    ...((pageDataNoAnswers || pageWithAnswersDataId)?.contentSection?.searchContentSections ||
      pageWithAnswersDataUrl?.contentSection.getSectionsByPageUrl ||
      []),
  ]
    .sort(sortFunc)
    .map((searchContentSection) => {
      return {
        ...searchContentSection,
        sectionContainers: [...searchContentSection.sectionContainers].sort(sortFunc).map((sectionContainer) => {
          return {
            ...sectionContainer,
            snippets: [...sectionContainer.snippets].sort(sortFunc),
          };
        }),
      };
    });

  return { page, pageIds, isPageLoading };
}
