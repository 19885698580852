import { useEffect } from 'react';

import { DocumentNode, QueryHookOptions, useLazyQuery as useApolloLazyQuery } from '@apollo/client';
import { useAccessToken, useTempToken } from 'contexts/Authorization/Context';

import { IOptions } from './useApolloWrapper';

export function useQuery(query: DocumentNode, options: IOptions & QueryHookOptions = {}) {
  const { accessToken, anon, endpoint, localError, localLoading, ...apolloOptions } = options || {};

  const storedToken = useAccessToken();
  const storedTempToken = useTempToken();

  apolloOptions.context = {
    ...apolloOptions.context,
    headers: {
      ...apolloOptions.context?.headers,
      custom_build_guid: process.env.REACT_APP_PROJECT_ID,
      project_id: process.env.REACT_APP_PROJECT_ID,
      ...(() => {
        const authorization = accessToken || storedToken || storedTempToken;
        if (authorization && !anon) {
          return { authorization };
        }
        return {};
      })(),
    },
  };

  const value = useApolloLazyQuery(query, apolloOptions);

  useEffect(() => {
    if (storedToken || storedTempToken || (anon && !value[1].called)) {
      value[0]();
    }
  }, [storedToken, storedTempToken]);

  return value[1];
}
